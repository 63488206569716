import axios from "axios";
// Docs: https://ws.geonorge.no/kommuneinfo/v1/
const API_ENDPOINT = "https://api.kartverket.no/kommuneinfo/v1";

export const getFylke = async (fylkesnummer: string, filtrer = "") => {
  const res = await axios.get(`${API_ENDPOINT}/fylker/${fylkesnummer}`, {
    params: {
      filtrer,
    },
  });
  return res;
};

export const getFylker = async (opt?: { filtrer?: string; sorter?: string }) => {
  const { filtrer = "fylkesnavn,fylkesnummer", sorter = "fylkesnavn" } = opt || {};
  const res = await axios.get(`${API_ENDPOINT}/fylker`, {
    params: { sorter, filtrer },
  });
  return res;
};

const defaultKommuneFilter = "kommunenavn,kommunenummer";

export const getKommune = async (kommunenummer: string, filtrer = defaultKommuneFilter) => {
  const res = await axios.get(`${API_ENDPOINT}/kommuner/${kommunenummer}`, {
    params: {
      filtrer,
    },
  });
  return res;
};

export const getKommuner = async (opt?: { knavn?: string; filtrer?: string; sorter?: string }) => {
  const { knavn, filtrer = defaultKommuneFilter, sorter = "kommunenavn" } = opt || {};
  if (!knavn) {
    const res = await axios.get(`${API_ENDPOINT}/kommuner`, {
      params: {
        filtrer,
        sorter,
      },
    });
    return res;
  }
  const res = await axios.get(`${API_ENDPOINT}/sok`, {
    params: {
      knavn,
      filtrer,
      sorter,
    },
  });
  return res.data;
};
