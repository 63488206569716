
import { api } from "@/api/api";
import { ApiGetAreaDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import AreaModal from "@/components/administration/location/AreaModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTable from "@/components/shared/table/BaseTable.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "AreaPage",
  components: { AreaModal, BaseModal, BaseTable, BaseLayout },

  setup() {
    const router = useRouter();
    const search = ref("");
    const modalData = ref(getInitialModalData());
    const areaList = ref<ApiGetAreaDto[]>([]);

    const headers = [
      { text: "Lokasjon", value: "place" },
      { text: "Handlinger", value: "actions" },
      { text: "Kommune", value: "municipality" },
      {
        text: "Fylke",
        value: "county",
      },
      { text: "Land", value: "country" },
    ];

    const loadData = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        areaList.value = (await api.area.getAreasAsync()).data;
      });
    };

    onMounted(() => {
      loadData();
    });

    const openNewArea = useOpenModal(ModalType.Add, "lokasjon", modalData);
    const openEditArea = useOpenModal(ModalType.Edit, "lokasjon", modalData);

    const navigateToArea = (item: ApiGetAreaDto) => {
      router.push({
        name: "locations",
        params: { areaId: item.id.toString() },
      });
    };

    return {
      navigateToArea,
      loadData,
      modalData,
      openEditArea,
      openNewArea,
      areaList,
      search,
      headers,
    };
  },
});
