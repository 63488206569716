
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { defineComponent, onMounted, ref, PropType } from "@vue/composition-api";
import { useStore } from "@/shared/useHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { api } from "@/api/api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { StoreState } from "@/store/store.state.interface";
import { ApiSsbCountryDto, ApiUpsertAreaDto } from "@/api/generated/Api";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import Vue from "vue";
import { getFylker, getKommuner } from "@/api/external/kommuneinfo";

const getAreaInitialData = (): ApiUpsertAreaDto => ({
  country: "Norge",
  countryCode: "NO",
});

export default defineComponent({
  name: "AreaModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    area: {
      type: Object as PropType<ApiUpsertAreaDto>,
      default: getAreaInitialData,
    },
    areaId: {
      type: Number,
    },
  },

  setup({ modalType, area, areaId }, context) {
    const store = useStore<StoreState>();
    const countyList = ref<{ fylkesnavn: string }[]>([]);
    const municipalityList = ref<{ kommunenavn: string }[]>([]);
    const countryList = ref<ApiSsbCountryDto[]>([]);
    const areaValues = ref(deepCloneObject(area));

    const loadData = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await Promise.all([loadCountryList(), loadCounty(), loadMunicipalities()]);
      });
    };

    onMounted(() => {
      loadData();
    });

    const loadCountryList = async () => {
      countryList.value = (await api.ssb.getCountriesAsync()).data;
    };

    const loadCounty = async () => {
      countyList.value = (await getFylker()).data;
    };

    const loadMunicipalities = async () => {
      municipalityList.value = (await getKommuner()).data;
    };

    const onSubmit = async () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();

      if (!isValid || !areaValues.value) {
        return;
      }
      await globalLoadingWrapper({ blocking: true }, async () => {
        if (modalType === ModalType.Add) {
          await api.area.createAreaAsync(areaValues.value);
          openNotification(store, NotificationItemType.Success, "Området er lagt til");
        } else if (modalType === ModalType.Edit) {
          if (!areaId) {
            openNotification(store, NotificationItemType.Error, "Ugyldig område");
            return;
          }
          await api.area.updateAreaAsync(areaId, areaValues.value);
          openNotification(store, NotificationItemType.Success, "Området er redigert");
        }
        context.emit("close");
        context.emit("update");
      });
    };

    return {
      onSubmit,
      loadMunicipalities,
      loadCountryList,
      loadCounty,
      areaValues,
      countryList,
      countyList,
      municipalityList,
      validateNotEmpty,
    };
  },
});
