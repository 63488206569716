var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Navn på lokasjon",
      "rules": _vm.validateNotEmpty,
      "required": ""
    },
    model: {
      value: _vm.areaValues.place,
      callback: function callback($$v) {
        _vm.$set(_vm.areaValues, "place", $$v);
      },
      expression: "areaValues.place"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Land",
      "items": _vm.countryList,
      "rules": _vm.validateNotEmpty,
      "item-text": "name",
      "item-value": "name",
      "required": "",
      "disabled": ""
    },
    model: {
      value: _vm.areaValues.country,
      callback: function callback($$v) {
        _vm.$set(_vm.areaValues, "country", $$v);
      },
      expression: "areaValues.country"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Fylke",
      "items": _vm.countyList,
      "item-text": function itemText(item) {
        return "".concat(item.fylkesnavn);
      },
      "clearable": "",
      "rules": _vm.validateNotEmpty,
      "required": ""
    },
    model: {
      value: _vm.areaValues.county,
      callback: function callback($$v) {
        _vm.$set(_vm.areaValues, "county", $$v);
      },
      expression: "areaValues.county"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Kommune",
      "items": _vm.municipalityList,
      "item-text": function itemText(item) {
        return "".concat(item.kommunenavn);
      },
      "clearable": "",
      "rules": _vm.validateNotEmpty,
      "required": ""
    },
    model: {
      value: _vm.areaValues.municipality,
      callback: function callback($$v) {
        _vm.$set(_vm.areaValues, "municipality", $$v);
      },
      expression: "areaValues.municipality"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }