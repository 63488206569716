var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_c('AreaModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "area": _vm.modalData.existingItem,
      "areaId": _vm.modalData.existingItemId
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "update": _vm.loadData
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "pr-0"
        }, [_vm._v(" Lokasjoner ")]), _c('v-col', {
          staticClass: "pl-1"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "max-width": "30%"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "right": "",
                  "color": "white"
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-message-question-outline ")])];
            }
          }])
        }, [_c('span', [_vm._v(" Dette registeret inneholder lokasjoner som skal knyttes til kurs og studier i Universum. Før du oppretter nye, sjekk om lokasjon finnes fra før ")])])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "createNewArea"
          },
          on: {
            "click": _vm.openNewArea
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Legg til lokasjon ")], 1)];
      },
      proxy: true
    }])
  }, [[_c('BaseTable', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.areaList,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.navigateToArea(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis adresse til " + _vm._s(item.place))])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.openEditArea(item, item.place);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger " + _vm._s(item.place))])])];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref5) {
        var headers = _ref5.headers,
            item = _ref5.item;
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_vm._v("More info about " + _vm._s(item.name))])];
      }
    }])
  })]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }